import React, { useState } from 'react'
import { Button, OverlayLoading, PasswordField, TextField } from '../../../components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { FaGoogle } from "react-icons/fa";
import { useLogin, useSocialLogin } from '../../../apis/AuthApi'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'



const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const logInValidationSchema = Yup.object({
    email: Yup.string()
        .matches(emailValidationRegex, "Invalid email address")
        .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
})

const Login = () => {

    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: "",
        password: ""
    }

    const { mutate: loginMutate, isPending: isLoggingIn } = useLogin();
    const { mutate: socialLoginMutate, isPending: isSocilaLoggingIn } = useSocialLogin();


    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            axios
                .get("https://www.googleapis.com/oauth2/v1/userinfo", {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                })
                .then(({ data }) => {
                    const body = {
                        email: data.email,
                        name: data.name,
                        access_token: data.id,
                    };
                    socialLoginMutate(body)
                });
        },
    });


    const onFormSubmit = (val, action) => {
        loginMutate({
            ...val,
            "role": "user"
        })
        action.resetForm()
    }

    return (
        <>

            {(isLoggingIn || isSocilaLoggingIn) &&
                <OverlayLoading />
            }

            <div className='bg-[#E5F0FD] py-12 edge-spacing'>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={logInValidationSchema}
                >
                    {() => (
                        <Form className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[80%] lg:w-1/2 m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                            <div className='font-medium text-xl'>Sign in</div>

                            <div className='flex items-center justify-start gap-1'>

                                <div className='text-customGray'>Create new one?</div>
                                <Button
                                    onClick={() => navigate("/user/register")}
                                    className={"text-primary"}
                                    label={"Sign Up"}
                                />

                            </div>

                            <div>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete={'email'}
                                />
                            </div>
                            <div>
                                <PasswordField
                                    label="Password"
                                    name="password"
                                    placeholder="Enter Your Password"
                                    type={`${showPassword ? "text" : "password"}`}
                                    setShowPassword={setShowPassword}
                                    showPassword={showPassword}
                                />
                            </div>


                            <div className='flex justify-start items-center gap-1'>
                                <Button
                                    className='text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                    type='submit'
                                    label={'SIGN IN'}
                                />
                                <Button
                                    className='rounded-md text-hover px-3 py-3 hover:no-underline underline'
                                    onClick={() => navigate("/user/reset-password")}
                                    label={'Forgot Your Password?'}
                                />
                            </div>

                            <div>
                                <Button
                                    onClick={googleLogin}
                                    className='mt-2 w-full text-sm font-medium rounded text-white px-6 py-3 bg-[#6C757D] flex justify-center items-center gap-2'
                                    type='button'
                                >
                                    <FaGoogle />
                                    <div>Login With Google</div>
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik >
            </div >
        </>
    )
}

export default Login

