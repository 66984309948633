import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { Button, OverlayLoading } from '../../components';
import { FaStripe } from 'react-icons/fa';
import { useGetProfile } from '../../apis/AuthApi';
import { useCreateCharge, useCreateCustomer, usePaymentApi } from '../../apis/PayApi';
import { convertAudToCents, convertCentsToAud } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';


const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [cardError, setCardError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvcError, setCvcError] = useState('');


    const catDetail = useSelector(state => state.storageReducer.eventBookData)
    const bookData = catDetail?.booking_detail;
    const eventId = bookData?.event_id;


    const { data: catList } = useGetProfile();
    const info = catList?.user_detail;

    const userData = {
        id: info?.id,
        image: info?.image_url,
        name: info?.name,
        email: info?.email,
        contact: info?.user_info?.phone,
        address: info?.user_info?.address,
    }

    const totalTickets = bookData?.total_ticket;
    const totalCost = bookData?.sub_total;
    const discountAmt = bookData?.discount_amount;
    const couponCode = bookData?.coupon_code;
    const gstAmount = bookData?.gst_amt;

    const { mutateAsync: createCustomerMutate } = useCreateCustomer();
    const { mutateAsync: createChargeMutate } = useCreateCharge();
    const { mutate: payMutate, isPending } = usePaymentApi();


    const formik = useFormik({
        initialValues: {
            cardholderName: '',
        },
        validationSchema: Yup.object({
            cardholderName: Yup.string().required('Cardholder name is required'),
        }),
        onSubmit: async (values) => {

            if (!stripe || !elements) {
                return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
                return;
            }

            try {
                setLoading(true);

                // create a token
                const { token, error: tokenError } = await stripe.createToken(cardNumberElement, {
                    name: values.cardholderName,
                });

                if (tokenError) {
                    setCardError(tokenError.message);
                    setLoading(false);
                    return;
                }

                // this should be in backend!
                // const createCustomerBody = {
                //     name: values.cardholderName,
                //     email: userData?.email,
                //     source: token.id
                // }
                // cardToken can be used only once so either create customer and use customerId or directly use cardToken
                // cardToken : token received from stripe.createToken

                // const customer = await createCustomerMutate(createCustomerBody);

                // use either token.id or customerId
                // customerId is meaningless when created in frontend. @JESUS TAKE THE WHEEL
                const createChargeBody = {
                    // source: "tok_visa",
                    source: token.id,
                    amount: convertAudToCents(totalCost),
                    currency: "AUD",
                    description: `Charge from your order at OlaTicket. Name: ${userData.name} email:${userData.email} event id: ${eventId}`,
                    statement_descriptor_suffix: "CHR_CLASSIDEALS",
                }

                // this also should be in backend. JESUS TAKE THE WHEEL
                const charge = await createChargeMutate(createChargeBody);


                const formattedDate = dayjs.unix(charge.created).format('YYYY-MM-DD HH:mm:ss');

                const body = {
                    "event_id": bookData?.event_id,
                    "coupon_code": couponCode,
                    "ticket_detail": bookData?.ticket_detail,
                    "total_ticket": totalTickets,
                    "booking_ticket": bookData?.booking_ticket?.map(val => val.id),
                    "discount_amount": discountAmt,
                    "price": totalCost,
                    "gst_amt": gstAmount,
                    "payed_amt": convertCentsToAud(charge?.amount),
                    "date": formattedDate,
                    "user_id": userData?.id,
                    "transactionId": charge.id,
                    "paymentMethod": charge.payment_method,
                    "receiptUrl": charge.receipt_url
                }
                payMutate(body)

                setLoading(false);
            } catch (error) {
                toast.error('Payment failed');
                setLoading(false);
            }
        },
    });

    const handleCardChange = (event) => {
        setCardError(event.error ? event.error.message : '');
    };

    const handleExpiryChange = (event) => {
        setExpiryError(event.error ? event.error.message : '');
    };

    const handleCvcChange = (event) => {
        setCvcError(event.error ? event.error.message : '');
    };

    return (
        <>
            {isPending &&
                <OverlayLoading />
            }
            <form onSubmit={formik.handleSubmit} className="max-w-lg mx-auto p-4 border rounded">
                <div>
                    <FaStripe className="text-6xl" />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardholderName">
                        Cardholder Name
                    </label>
                    <input
                        id="cardholderName"
                        name="cardholderName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cardholderName}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {formik.touched.cardholderName && formik.errors.cardholderName ? (
                        <div className="text-red-500 text-xs italic">{formik.errors.cardholderName}</div>
                    ) : null}
                </div>


                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Card Number
                    </label>
                    <CardNumberElement className="p-2 border rounded mb-2" onChange={handleCardChange} />
                    {cardError && <div className="text-red-500 text-xs italic">{cardError}</div>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Expiry Date
                    </label>
                    <CardExpiryElement className="p-2 border rounded mb-2" onChange={handleExpiryChange} />
                    {expiryError && <div className="text-red-500 text-xs italic">{expiryError}</div>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        CVC
                    </label>
                    <CardCvcElement className="p-2 border rounded mb-2" onChange={handleCvcChange} />
                    {cvcError && <div className="text-red-500 text-xs italic">{cvcError}</div>}
                </div>

                <Button
                    type="submit"
                    className="mt-2 bg-primary px-4 py-3 text-white rounded"
                    label={`Pay $${totalCost} and Book Now`}
                    disabled={loading}
                />
            </form>
        </>
    );
};

export default CheckoutForm;
/*

                // const customerData = await createCustomer({ id: token.id, email: values.email });


                // const charge = await stripe.charges.create({
                //     amount: 1099,
                //     currency: 'usd',
                //     source: 'tok_visa',
                // });


                // const chargeData = await createCharge({
                //     id: token.id,
                //     amount: totalCost,
                //     user: {
                //         first_name: values.cardholderName,
                //         last_name: '',
                //         email: values.email,
                //         id: '12345', // replace with actual user id or event id
                //     },
                //     customerId: customerData.id,
                // });
                // console.log(chargeData)
*/