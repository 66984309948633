import { useMutation } from "@tanstack/react-query";
import { handleError, showToast } from "../utils/helpers";
import { axiosInstance } from "./baseConfig";

const createSupport = async (body) => {
    const url = `/support-create`;
    const response = await axiosInstance.post(url, body);
    return response.data;
};

export const useCreateSupport = () => {

    return useMutation({
        mutationFn: createSupport,
        onSuccess: (data) => {
            showToast('success', data.message);
        },
        onError: (err) => {
            handleError(err);
        }
    });
};
