import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstance } from "./baseConfig";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleError, showToast } from "../utils/helpers";
import { cleanEventBook } from "../redux/storage/action";
import axios from "axios";



/*********************************************** ***********************************************/

const history = async ({ pageParam = 1, status }) => {
    const url = `/history?status=${status}&page=${pageParam}`;
    const response = await axiosInstance.get(url);
    return response.data?.data;
};

const getPageNumberFromUrl = (url) => {
    try {
        const urlObj = new URL(url);
        const pageNumber = urlObj.searchParams.get('page');
        return pageNumber ? parseInt(pageNumber, 10) : null;
    } catch (error) {
        return null;
    }
};


export const useInfiniteHistoryList = (status) => {
    return useInfiniteQuery({
        queryKey: ['history', status],
        queryFn: ({ pageParam }) => history({ pageParam, status }),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => getPageNumberFromUrl(lastPage?.next_page_url),
        enabled: !!status
    })
};

/*********************************************** ***********************************************/

const historyDetail = async (id) => {
    const url = `history-detail/${id}`;
    const response = await axiosInstance.get(url);
    return response.data?.data;
};

export const useHistoryDetail = (id) => {
    return useQuery({
        queryFn: () => historyDetail(id),
        queryKey: ["historyDetail", id],
        enabled: !!id
    })
};

/*********************************************** ***********************************************/

const paymentApi = async (body) => {
    const url = `/event-booking-payment`;
    const response = await axiosInstance.post(url, body);
    return response.data;
};

export const usePaymentApi = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: paymentApi,
        onSuccess: (data) => {
            showToast('success', data.message);
            navigate(`/history`, { replace: true });
            dispatch(cleanEventBook())
        },
        onError: (err) => {
            handleError(err);
        }
    });
};


/*********************************************** ***********************************************/


const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_JESUS_SAVE_THIS_PROJECT;

const createHeaders = () => ({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${STRIPE_API_KEY}`,
});

export const createCustomer = async (body) => {
    const response = await axios.post('https://api.stripe.com/v1/customers', body,
        { headers: createHeaders() }
    );
    return response.data;
};

export const createCharge = async (body) => {
    const response = await axios.post('https://api.stripe.com/v1/charges', body,
        { headers: createHeaders() }
    );
    return response.data;
};

// export const createCharge = async ({ id, amount, user, customerId }) => {
//     const myAmount = parseFloat(amount).toFixed(2);
//     const AMT = myAmount.indexOf('.') !== -1 ? myAmount.split('.').join('') : `${myAmount}00`;

//     const data = id
//         ? `amount=${encodeURIComponent(AMT)}&currency=AUD&description=${encodeURIComponent(`Charge from your order at Event Management App. Name: ${user.first_name} ${user.last_name} email:${user.email} event id: ${user.id}`)}&statement_descriptor=CHR_CLASSIDEALS&source=${encodeURIComponent(id)}`
//         : `amount=${encodeURIComponent(AMT)}&currency=AUD&description=${encodeURIComponent(`Charge from your order at Classi Deal App. Name: ${user.first_name} ${user.last_name} email:${user.email} token customers: ${customerId}`)}&statement_descriptor=CHR_CLASSIDEALS&customer=${encodeURIComponent(customerId)}`;

//     const response = await axios.post('https://api.stripe.com/v1/charges', data, { headers: createHeaders() });
//     return response.data;
// };


export const useCreateCustomer = () => {
    return useMutation({
        mutationFn: createCustomer,
        onError: (err) => {
            console.log(err, "customer")
        }
    });
};

export const useCreateCharge = () => {
    return useMutation({
        mutationFn: createCharge,
        onError: (err) => {
            // handleError(err);
            console.log(err, "charges")
        }
    });
};

/**
 * 
{
    "data": {
        "current_page": 1,
        "data": [],
        "first_page_url": "https://olaticket.com.au/public/api/history?page=1",
        "from": 1,
        "last_page": 3,
        "last_page_url": "https://olaticket.com.au/public/api/history?page=3",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "https://olaticket.com.au/public/api/history?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": "https://olaticket.com.au/public/api/history?page=2",
                "label": "2",
                "active": false
            },
            {
                "url": "https://olaticket.com.au/public/api/history?page=3",
                "label": "3",
                "active": false
            },
            {
                "url": "https://olaticket.com.au/public/api/history?page=2",
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": "https://olaticket.com.au/public/api/history?page=2",
        "path": "https://olaticket.com.au/public/api/history",
        "per_page": 12,
        "prev_page_url": null,
        "to": 12,
        "total": 27
    }
}
 */